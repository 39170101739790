import React, { useState, type FC, type MouseEvent } from 'react'
import RecordTable from '../../components/records-table'
import InputBox from '../../components/inputbox'
import type { AssetRecordDetailsProps } from './utils/types'
import ActionButton from '../../components/action-button'
import closeIcon from '../../utility/img/cancel.png'
import ConfirmationModal from '../../components/confirm-popup'
import { useAssetDetails } from './hooks/useAssetDetails'
import { TRADER_INPUT_FIELDS_MAP } from '../../utility/constant'
import GenericDropdown from '../../components/dropdown'
import { type CombinedRecordType } from '../../utility/types'
import Message from '../../components/message'
import { getFRCodeList } from './utils/assetBucketsHelpers'
import MiniNotepad from '../../components/notepad'
import ShowNotes from '../../components/show-notes'
import { formatInputToDollar } from '../../utility/formatting'
import ReconcileSummaryList from './reconcile-components/ReconcileSummaryList'
import ReconcileDetails from './reconcile-components/ReconcileDetails'

const AssetBucketsPopUp: FC<AssetRecordDetailsProps> = ({
  show,
  onClose,
  batchId,
  batchUniqueId,
  assetType,
  setMessage,
  setMessageColor,
  updateError,
  status,
  basketOverall,
  fundingRequestCode,
  ttlNotional,
  ttlQuantity,
  reconcileIndicator,
  reconcileCount
}) => {
  const [localNotional, setLocalNotional] = useState(ttlNotional)
  const [localQuantity, setLocalQuantity] = useState(ttlQuantity)
  const [isReconcilePopupOpen, setIsReconcilePopupOpen] = useState(false)
  const [selectedRecordIndex, setSelectedRecordIndex] = useState<number>(0)

  const disableEdit = /(COMPL)/.test(status) || (/(RISK)/.test(status) && fundingRequestCode)

  const {
    records,
    setRecords,
    inputData,
    setInputData,
    isDataReady,
    loading,
    error,
    QueryWithRetryComponent,
    isConfirmationDialogActive,
    setIsConfirmationDialogActive,
    isRemoveRecordModalOpen,
    setIsRemoveRecordModalOpen,
    handleRecordRemoval,
    confirmRecordRemoval,
    dataChanged,
    ableToProceed,
    isMutating,
    isSecondaryMutating,
    FRDropDownOptions,
    editableField,
    handleDiscard,
    handleSaveExit,
    handleCloseIconClick,
    selectedFRCode,
    originalFRCode,
    handleDropdownChange,
    fetchFRs,
    handlePrimaryActionWithValidation,
    handleCancelClick,
    getPrimaryLabel,
    readOnly,
    recalcMessage,
    recalcMessageColor,
    setRecalcMessage,
    setRecalcMessageColor,
    traderNote,
    setTraderNote,
    displayingNotes,
    setHasFlag,
    reconciliationResultData,
    loadingReconciliation,
    errorReconciliation
  } = useAssetDetails(
    {
      batchId,
      batchUniqueId,
      assetType,
      setMessage,
      setMessageColor,
      status,
      onClose,
      basketOverall,
      updateError,
      fundingRequestCode,
      testDetails: true,
      reconcileIndicator,
      reconcileCount
    }
  )

  if (loading || error) return <QueryWithRetryComponent />
  if (!show) return null

  if (loadingReconciliation) return <p>Loading reconciliation data...</p>
  if (errorReconciliation) return <p>{errorReconciliation.message}</p>

  const stopPropagation = (event: MouseEvent) => { event.stopPropagation() }

  const handleOpenReconciliationPopup = () => {
    setIsReconcilePopupOpen(true)
  }

  const handleCloseReconciliationPopup = () => {
    setIsReconcilePopupOpen(false)
    setSelectedRecordIndex(0)
  }

  return (
    <>
    <div className="fixed z-50 inset-0 overflow-auto flex justify-center items-center">
      {isConfirmationDialogActive && (
        <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      )}
      <div className="fixed inset-0 transition-opacity bg-gray-500 opacity-75" aria-hidden="true" />
      <div onClick={stopPropagation} className="bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:max-w-[90%] mx-auto w-auto z-5 max-h-[90%] h-auto">
        <div className='flex flex-row justify-between'>
            <button onClick={handleCloseIconClick} className="transition duration-150 ease-in-out pt-4 pl-4 rounded-full">
              <img src={closeIcon} alt='Close' width={24} height={24}/>
            </button>
            <div className='flex-grow mx-4 overflow-hidden flex justify-center items-center'>
              {recalcMessage && (
                <div className="overflow-auto whitespace-pre-wrap break-words">
                  <Message message={recalcMessage} color={recalcMessageColor} clearMessage={() => { setRecalcMessage('') }} />
                </div>
              )}
            </div>
        </div>
        <div className="overflow-auto max-h-[20vh] text-xs md:text-base px-2 max-w-fit md:overflow-visible">
          <InputBox
          fields={TRADER_INPUT_FIELDS_MAP(assetType)}
          inputData={inputData}
          setInputData={setInputData}
          isDataReady={isDataReady}
          setMessage={setRecalcMessage}
          setMessageColor={setRecalcMessageColor}
          readOnly={readOnly}
          setHasFlag={setHasFlag} />
        </div>
        <div className="overflow-x-auto max-h-[45vh]">
          <div className="p-4">
            <RecordTable<CombinedRecordType>
              records={records}
              setRecords={setRecords}
              onRecordRemove={handleRecordRemoval}
              editableFields={editableField}
              isDataReady={isDataReady}
              showEdit={!readOnly}
              showRemove={!readOnly}
              setLocalNotional={setLocalNotional}
              setLocalQuantity={setLocalQuantity}
            />
            <div className='flex flex-col xl:flex-row space-y-2.5 xl:space-y-0 xl:space-x-2.5 h-full'>
              <div className="flex flex-1 min-w-0">
                <ShowNotes data={displayingNotes} />
              </div>
              <div className="flex flex-1 min-w-0">
                <MiniNotepad
                  noteText={traderNote}
                  setNoteText={setTraderNote}
                />
              </div>
              <div className='flex flex-1 flex-col space-y-2.5 xl:space-y-0 xl:flex-row pt-2 min-w-fit'>
                <div className="flex">
                  Notional MV:
                  <div className='ml-1 mr-2'>
                    <span className='bg-red-100 pl-2 pr-2'>{formatInputToDollar(localNotional ?? 0, { prefix: '$' })}</span>
                  </div>
                </div>
                <div className="flex">
                  Total Quantity:
                  <div className='ml-1 mr-2'>
                    <span className='bg-red-100 pl-2 pr-2'>{formatInputToDollar(localQuantity ?? 0, { digit: 0 })}</span>
                  </div>
                </div>
                <div className="flex">
                  Num of assets:
                  <div className='ml-1'>
                    <span className='bg-yellow-100 pl-2 pr-2'>
                      {records.filter(record => !record.isRemoved).length}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-between items-center p-4 pt-0 overflow-hidden'>
          <div className='flex flex-auto space-x-4 items-center'>
            {disableEdit
              ? <div className={`${fundingRequestCode ? 'visible' : 'invisible'} flex flex-1 p-5`}>
              Inter-company Loan:
              <div className="ml-1 pl-2 pr-2 mr-2 bg-lime-200">
                {fundingRequestCode}
              </div>
            </div>
              : <GenericDropdown
                options={getFRCodeList(FRDropDownOptions)}
                selectedValue={selectedFRCode ?? undefined}
                onChange={handleDropdownChange}
                displayMsg={originalFRCode ?? 'Select an Intercompany Loan'}
                fetchOptions={fetchFRs}
              />}
            {reconcileIndicator === 1 && reconcileCount > 0 && (
              <button onClick={handleOpenReconciliationPopup} className="text-red-500 underline">
                Reconcile Details: {reconcileCount}
              </button>
            )}
          </div>
          {!disableEdit && <ActionButton
            primaryLabel={getPrimaryLabel}
            secondaryLabel='Close'
            primaryAction={handlePrimaryActionWithValidation}
            secondaryAction={handleCancelClick}
            primaryDisabled={!ableToProceed && !dataChanged}
            primaryLoading={isMutating}
            secondaryLoading={isSecondaryMutating}
          />}
        </div>
      </div>
    </div>

    {isReconcilePopupOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
        <div className="overflow-auto bg-white rounded-lg shadow-lg w-3/4 h-3/4 p-4 relative max-h-fit">
        <div className='flex justify-between p-4'>
          <button onClick={handleCloseReconciliationPopup} className="transition duration-150 ease-in-out rounded-full">
            <img src={closeIcon} alt='Close' width={24} height={24}/>
          </button>
          <h2 className="text-xl font-bold text-justify">Reconciliation Details</h2>
          <ReconcileSummaryList
            resultData={reconciliationResultData?.summaryData ?? []}
            setSelectedRecordIndex={setSelectedRecordIndex}
            selectedRecordIndex={selectedRecordIndex}
          />
        </div>
          <ReconcileDetails
            summaryData={reconciliationResultData?.summaryData[selectedRecordIndex]}
            detailsData={reconciliationResultData?.detailsData}
          />
        </div>
      </div>
    )}

    <ConfirmationModal
      isOpen={isConfirmationDialogActive}
      onClose={handleDiscard}
      onConfirm={handleSaveExit}
      onCancel={async () => { setIsConfirmationDialogActive(false) }}
      question="Do you want to save changes?"
      title="Save Changes"
      confirmText="Save"
      cancelText="Discard"
      primaryLoading={isMutating}
      secondaryLoading={isSecondaryMutating}
    />
    <ConfirmationModal
      isOpen={isRemoveRecordModalOpen}
      onCancel={async () => { setIsRemoveRecordModalOpen(false) }}
      onConfirm={confirmRecordRemoval}
      question="Are you sure you want to remove this record?"
      title="Please Confirm"
      confirmText="Confirm"
      cancelText="Go Back"
      confirmButtonColor='bg-red-600 hover:bg-red-700 focus:ring-red-500'
    /></>
  )
}

export default React.memo(AssetBucketsPopUp)
